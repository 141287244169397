import { Box } from '@dltru/dfa-ui'
import { FC, ReactNode } from 'react'

import style from './infoContainer.m.less'

interface IInfoContainerProps {
    title: string
    body?: ReactNode
    footer?: ReactNode
}

export const InfoContainer: FC<IInfoContainerProps> = ({ title, body, footer }) => {
    return (
        <Box className={style.infoContainer}>
            <Box className={style.infoContainerHeader}>
                <Box className={style.infoContainerTitle}>{title}</Box>
            </Box>
            {body && <Box className={style.infoContainerBody}>{body}</Box>}
            {footer && <Box className={style.infoContainerFooter}>{footer}</Box>}
        </Box>
    )
}
