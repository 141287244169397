import { FC } from 'react'

import { InfoContainer } from '../InfoContainer/InfoContainer'

interface IInfoContainerQualifyRecallProps {
    onAction?: () => void
}

export const InfoContainerQualifyRecall: FC<IInfoContainerQualifyRecallProps> = () => {
    const title = 'Исключение из Реестра квалифицированных инвесторов'
    const body = (
        <>
            <p>
                Для исключения из реестра лиц, признанных квалифицированными инвесторами, обратитесь
                к оператору ИС.
            </p>
            <p>+7 (495) 548 - 58 - 25</p>
        </>
    )

    const footer = <></>

    return (
        <>
            <InfoContainer title={title} body={body} footer={footer} />
        </>
    )
}
