import { FC } from 'react'
import { useSelector } from 'react-redux'

import { DocumentsEnum } from '@dltru/dfa-models'
import { Bill } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { InfoContainer } from '../InfoContainer/InfoContainer'


export const InfoContainerReestr: FC = () => {
    const userId = useSelector(authSelector.selectUserUid)
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)

    if (!userId) {
        return null
    }

    const title = 'Выписка из Реестра'
    const body = (
        <p>
            Вы можете подать онлайн-заявку на предоставление выписки из реестра лиц, признанных
            квалифицированными инвесторами, отражающую сведения о Вас, внесённые в Систему. За
            данную операцию взимается комиссия.
        </p>
    )

    const footer = (
        <div>
            <DownloadBtn
                documentType={DocumentsEnum.register_of_qualified_investors}
                params={{ user_id: userId }}
                disabled={!qualifyInfo?.is_qualified}
                title="Заказать выписку"
                icon={<Bill />}
            />
        </div>
    )

    return <InfoContainer title={title} body={body} footer={footer} />
}