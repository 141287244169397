import { blockedRoles } from '@dltru/dfa-models'
import { Box, Card, Col, Divider, QualificationInfo, Row } from '@dltru/dfa-ui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { profileSelector } from '@store/profiles/details/selectors'
import { getCurrentStatementQualify, qualifyStatementSlice } from '@store/qualifyStatement'

//import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'
import { AlertUserBlock } from '../ProfileTab/AlertUserBlock'
import { InfoContainerQualify, InfoContainerQualifyRecall } from './components/InfoContainerQualify'
import { InfoContainerReestr } from './components/InfoContainerReestr'

export const QualificationTab = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    const authData = useSelector(profileSelector.selectAuthData)
    const showBlockAlert = authData && blockedRoles.includes(authData.role)
    const profileTypes = useSelector(profileSelector.selectProfileTypes)
    const profile = useSelector(profileSelector.selectProfile)

    useEffect(() => {
        if (profile) {
            reduxDispatch(getCurrentStatementQualify(`?user_uuid=${profile.user_uuid}`))
        }
    }, [profile])

    return (
        <>
            <Card>
                <Box margin={[16, 8, 0, 8]}>
                    {showBlockAlert && (
                        <>
                            <AlertUserBlock />
                            <Divider margin={[24, 0]} />
                        </>
                    )}
                    <Row>
                        <Col span={16}>
                            <QualificationInfo
                                qualifyInfo={qualifyInfo}
                                profileType={profileTypes?.[0]}
                            />
                        </Col>
                        {/* INFO не входит в 0.9.5 */}
                        {/*(<Col span={8}>
                            <Box align={'flex-end'}>
                                <Link to="/remove-from-users">
                                    <strong>Список ЦФА, доступных для приобретения</strong>
                                </Link>
                            </Box>
                        </Col>)*/}
                    </Row>
                    <Row gutter={16} style={{ marginTop: 24 }}>
                        <Col span={12}>
                            <InfoContainerReestr />
                        </Col>
                        <Col span={12}>
                            {qualifyInfo?.is_qualified ? (
                                <InfoContainerQualifyRecall />
                            ) : (
                                <InfoContainerQualify
                                    onAction={() => {
                                        reduxDispatch(
                                            qualifyStatementSlice.actions.clearCurrentDataAndEvents(),
                                        )
                                        navigate('../qualification-statement')
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Box>
            </Card>
        </>
    )
}
