// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionButton_fbwU9 {\n  width: fit-content !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/QualificationTab/components/InfoContainerQualify/InfoContainerQualify.m.less"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ","sourcesContent":[".actionButton {\n    width: fit-content !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButton": "actionButton_fbwU9"
};
export default ___CSS_LOADER_EXPORT___;
