import { Button, ClipboardImport, Divider } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { InfoContainer } from '../InfoContainer/InfoContainer'
import { QualificationStatement } from '../QualificationStatement'
import style from './InfoContainerQualify.m.less'

interface IInfoContainerQualifyProps {
    onAction: () => void
}

const finalQualifyState = ['declined', 'approved']

export const InfoContainerQualify: FC<IInfoContainerQualifyProps> = ({ onAction }) => {
    //const [AgreementModal, setAgreementModal] = useState(false)
    const qualifyStatement = useSelector(qualifyStatementSelector.selectCurrentQualifyStatement)
    const showRequestBtn =
        !qualifyStatement?.status || finalQualifyState.includes(qualifyStatement.status)

    const onActionHandler = () => {
        onAction()
    }

    /*const onReglamentHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        setAgreementModal(true)
    }*/

    const title = 'Присвоение статуса квалифицированного инвестора'
    const body = (
        <>
            <p>Вы можете подать заявление на признание квалифицированным инвестором.</p>
            <p>
                Порядок принятия решения о признании лица квалифицированным инвестором утверждён
                <a
                    //onClick={onReglamentHandler}
                    href={process.env.PUBLIC_URL + '/docs/reglament.docx'}
                    target="_blank"
                    rel="noreferrer"
                >
                    {' '}
                    Регламентом
                </a>
                . <br />
                За данную операцию взимается комиссия.
            </p>
            <Divider />
            {qualifyStatement && qualifyStatement.status !== 'approved' && (
                <QualificationStatement statement={qualifyStatement} />
            )}
        </>
    )

    const footer = showRequestBtn ? (
        <Button
            borderRadius={12}
            onClick={onActionHandler}
            icon={<ClipboardImport />}
            className={style.actionButton}
        >
            Подать заявку
        </Button>
    ) : (
        <></>
    )

    return (
        <>
            <InfoContainer title={title} body={body} footer={footer} />
            {/*<Agreement isModalVisible={AgreementModal} setIsModalVisible={setAgreementModal} />*/}
        </>
    )
}
